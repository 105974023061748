import React, { useEffect, useState } from "react";
import { Drawer, Modal, Spin, Button, Select, Tooltip, message } from "antd";
// import {
//   DeactiveCard,
//   DeleteCard,
//   getSigleCardData,
// } from "../../../api/AdminRequest";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
// import { editData } from "../../../Redux/Actions/CardAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminBlock, adminCards, adminDelete, bukLocationClear, bulkQrUpdateUserCard, getAllAdminId, makeUserNormal, moveUserBetweenAdmins, moveUserUnderAdmin, singleLocClear, userBlock, userCardLocBukClear, userCards, userDelete } from "../../api/AdminRequest";
import Moment from 'moment'

const { confirm } = Modal;
const defaultProfileImage = 'https://zeeqr-files.s3.ap-south-1.amazonaws.com/assets/defaultProfile.jpg'




function UserDrawer({ onClose, viewId, open, setChange, users }) {
  // const dispatch = useDispatch();
  // console.log(viewId, 'llllllllllllllllllllll');
  const navigate = useNavigate();
  const [drawerData, setDrawerData] = useState("");
  const [cardStatus, setCardStatus] = useState("");
  const [loader, setLoader] = useState(true)
  const [delLoder, setDelLoder] = useState(false)
  const [statuslLoder, setStatuslLoder] = useState(false)
  const [cards, setCards] = useState('')
  const [cardId, setCardID] = useState(null)

  const [isMoveModalVisible, setIsMoveModalVisible] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [adminList, setAdminList] = useState([]);
  const [isMoving, setIsMoving] = useState(false);
  const [isNormalizing, setIsNormalizing] = useState(false);



  useEffect(() => {
    const singleBookedCards = async () => {
      try {
        setLoader(true)
        const { data } = users ? await userCards(viewId._id) : await adminCards(viewId._id);
        // console.log(data,"user data");
        if (data.success) {
          setCards(data.card.length);
          setCardID(data.card._id)

          setDrawerData(viewId);
          setCardStatus(viewId.status)
          setLoader(false)
        }

        // }
      } catch (error) {
        console.log(error);
      }
    };
    singleBookedCards();
  }, [viewId]);

  // console.log(drawerData, 'drawerData');
  

  // ----------- MOVE MODAL ----

    useEffect(() => {
      const fetchAdminList = async () => {
        try {
          const { data } = await getAllAdminId();
          if (data.success) {
            setAdminList(data.admin);
          }
        } catch (error) {
          console.error('Error fetching admin list:', error);
          toast.error('Failed to fetch admin list');
        }
      };
  
      // if (isMoveModalVisible) {
        fetchAdminList();
      // }
    }, []);

const showMoveModal = () => {
  setIsMoveModalVisible(true);
};

const handleMoveModalCancel = () => {
  setIsMoveModalVisible(false);
  setSelectedAdmin(null);
};

const handleMoveConfirm = () => {
  if (!selectedAdmin) {
    toast.error('Please select an admin');
    return;
  }

  confirm({
    title: 'Are you sure you want to move this user?',
    icon: <ExclamationCircleFilled />,
    content: 'This action cannot be undone.',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      handleMoveUser();
    },
  });
};

// const handleMoveUser = async () => {

//   try {
//     setIsMoving(true);
//     const {data} = await moveUserUnderAdmin(drawerData._id, selectedAdmin);
    
//     if (data.success) {
//       toast.success('User successfully moved under admin');
//       setChange(Date.now());
//       handleMoveModalCancel();
//       onClose();
//     } else {
//       toast.error(data?.message || 'Failed to move user');
//     }
//   } catch (error) {
//     console.error('Error moving user:', error);
//     toast.error(error.response?.data?.message || 'Failed to move user');
//   } finally {
//     setIsMoving(false);
//   }
// };
const handleMoveUser = async () => {
  try {
    setIsMoving(true);
    const { data } = drawerData.adminID 
      ? await moveUserBetweenAdmins(drawerData._id, selectedAdmin)
      : await moveUserUnderAdmin(drawerData._id, selectedAdmin);
    
    if (data.success) {
      // console.log(data.success,"successfully moved");
      
      // toast.success('User successfully moved');
      message.success('User successfully moved');
      setChange(Date.now());
      handleMoveModalCancel();
      onClose();
    } else {
      toast.error(data?.message || 'Failed to move user');
    }
  } catch (error) {
    console.error('Error moving user:', error);
    toast.error(error.response?.data?.message || 'Failed to move user');
  } finally {
    setIsMoving(false);
  }
};
// Replace the existing Move under Admin button with this:
const moveUnderAdminButton = (
  <Button
    className="w-30"
    onClick={showMoveModal}
  >
    Move under Admin
  </Button>
);

// Add this modal component just before the closing Drawer tag
// const moveModal = (
//   <Modal
//     title="Move User Under Admin"
//     open={isMoveModalVisible}
//     onCancel={handleMoveModalCancel}
//     footer={[
//       <Button key="cancel" onClick={handleMoveModalCancel}>
//         Cancel
//       </Button>,
//       <Button
//         // key="move"
//         className="bg-blue-600 hover:bg-blue-700 text-white"
//         // type="primary"
//         onClick={handleMoveConfirm}
//         loading={isMoving}
//         disabled={!selectedAdmin}
//       >
//         Move
//       </Button>,
//     ]}
//   >
//     <div className="py-4">
//       <p className="mb-2">Select admin to move user under:</p>
//       <Select
//         className="w-full"
//         placeholder="Select an admin"
//         onChange={value => setSelectedAdmin(value)}
//         value={selectedAdmin}
//       >
//         {adminList?.map(admin => (
//           <Select.Option key={admin._id} value={admin._id}>
//             {admin.name} ({admin.email})
//           </Select.Option>
//         ))}
//       </Select>
//     </div>
//   </Modal>
// );
const moveModal = (
  <Modal
    title={drawerData.adminID ? "Move User To Other Admin" : "Move User Under Admin"}
    open={isMoveModalVisible}
    onCancel={handleMoveModalCancel}
    footer={[
      <Button key="cancel" onClick={handleMoveModalCancel}>
        Cancel
      </Button>,
      <Button
        className="bg-blue-600 hover:bg-blue-700 text-white"
        onClick={handleMoveConfirm}
        loading={isMoving}
        disabled={!selectedAdmin}
      >
        Move
      </Button>,
    ]}
  >
    <div className="py-4">
      <p className="mb-2">
        {drawerData.adminID 
          ? "Select new admin to move user to:"
          : "Select admin to move user under:"}
      </p>
      <Select
        className="w-full"
        placeholder="Select an admin"
        onChange={value => setSelectedAdmin(value)}
        value={selectedAdmin}
      >
        {adminList?.map(admin => (
          <Select.Option 
            key={admin._id} 
            value={admin._id}
            disabled={admin._id === drawerData.adminID} // Disable current admin
          >
            {admin.name} ({admin.email})
            {admin._id === drawerData.adminID ? " (Current)" : ""}
          </Select.Option>
        ))}
      </Select>
    </div>
  </Modal>
);
// -----------------


// ---------- MAKE USER NORMAL ------
const handleMakeNormalUser = async () => {
  try {
    setIsNormalizing(true);
    const { data } = await makeUserNormal(drawerData._id);
    
    if (data.success) {
      // toast.success('Successfully converted to normal user');
      message.success('Successfully converted to normal user');
      setChange(Date.now());
      onClose();
    } else {
      toast.error(data?.message || 'Failed to convert user');
    }
  } catch (error) {
    console.error('Error converting user:', error);
    toast.error(error.response?.data?.message || 'Failed to convert user');
  } finally {
    setIsNormalizing(false);
  }
};

const showNormalUserConfirm = () => {
  confirm({
    title: "Convert to Normal User?",
    icon: <ExclamationCircleFilled />,
    content: "This action will remove all admin associations and team memberships. This may cause issues with existing configurations.",
    okText: "Yes, Convert",
    okType: "danger",
    cancelText: "No",
    onOk() {
      handleMakeNormalUser();
    },
  });
};

// Replace the existing button with this
const makeNormalUserButton = (
  <Tooltip 
    title="Warning: This action will remove admin associations and may affect existing configurations"
    color="yellow"
  >
    <Button
      className="text-white bg-red-600 hover:bg-red-700 w-30"
      onClick={showNormalUserConfirm}
      loading={isNormalizing}
    >
      Make As Normal User
    </Button>
  </Tooltip>
);


  const handleSubmit = () => {
    // const id = drawerData._id
    navigate("/edit-admin", { state: { drawerData } });
  };

  const handleDeactive = async () => {

    try {
      setStatuslLoder(true)
      const { data } = users ? await userBlock(drawerData._id) : await adminBlock(drawerData._id);
      // console.log(data);
      if (data.block) {
        console.log("sucess delete");
        setCardStatus("blocked")
        setStatuslLoder(false)
      }
      if (data.active) {
        setCardStatus("active")
        setStatuslLoder(false)
      }
      setChange(Date.now())
    } catch (error) {
      console.log(error);
      toast(error.response.data.message);
    }
  };

  const handleDelete = async () => {
    try {
      setDelLoder(true)
      const { data } = users ? await userDelete(drawerData._id) : await adminDelete(drawerData._id);
      // console.log(data);
      if (data.delete) {
        console.log("sucess delete");
        setChange(Date.now())
        onClose()
        setDelLoder(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDeactiveConfirm = () => {
    confirm({

      title: `${cardStatus === 'active' ? 'Are you sure to Block ' : 'Are you sure to Active'}`,
      icon: <ExclamationCircleFilled />,
      content: `${cardStatus === 'active' ? 'if you block, all the profile also get blocked. ' : 'if you activate, all the profile also get activate.'}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeactive();
        // { cardStatus === "active" ? setCardStatus("deactive") : setCardStatus("active") }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete?",
      icon: <ExclamationCircleFilled />,
      // content: `${admin ? 'if you delete the card the user also get deleted.' : ' }` ,
      content: `if you delete, all the profile also get deleted.`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const ExpiryDate = new Date(Moment(drawerData.createdDate) + drawerData.expiryDate * 24 * 60 * 60 * 1000)
  const currentDate = Moment(Date.now()).format('MM DD YYYY')
  const ExpriyDATE = Moment(ExpiryDate).format("MM DD YYYY")
  // console.log(currentDate, 'currwent ');
  // console.log(ExpriyDATE, 'EPIRY ');
  var diffDays = Moment(ExpriyDATE).diff(currentDate, 'days');
  // console.log(diffDays,'llllllllllllllldsayssssssssssssssssss');

  // const date1 = new Date(currentDate);
  // const date2 = new Date(ExpriyDATE);
  // const diffTime = Math.abs(date2 - date1);
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // console.log(diffTime,'exxxxxxxxxxxxxxxxxxxxxxxx');

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }
  const result = percentage(cards, drawerData.cardLimit)
  
    const handleClearLoc = (type) => {
      // console.log(cardId,"card ID");
      
      if(!viewId._id){
        return toast.error(
          "No id found",
          {
            position: "top-right",
            autoClose: 2500,
            theme: "dark",
          }
        );
      }
      confirm({
        title:
          "Are you sure you want to clear all the location data under this admin?",
        icon: <ExclamationCircleFilled />,
        content: "This will clear all location history and reset tap count to 0.",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        async onOk() {
          try {
            // const { data } = await (type ==="card" ? bukLocationClear(viewId._id) : type ==="ContactCard" ? singleContactCardLocClear(id) : singleReviewLocClear(id))
            const { data } = await userCardLocBukClear(viewId._id);
            // console.log(data);
  
            if (data.success) {
              toast.success("Location data cleared successfully", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              // Optionally refresh your data here
              // setTrigger(!trigger); // If you're using a trigger for refetch
            }
          } catch (error) {
            console.error("Error clearing location:", error);
            toast.error(
              error?.response?.data?.message || "Error clearing location data",
              {
                position: "top-right",
                autoClose: 2500,
                theme: "dark",
              }
            );
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };
    const handleBulkQrUpdate = (type) => {
      // console.log(cardId,"card ID");
      
      if(!viewId._id){
        return toast.error(
          "No id found",
          {
            position: "top-right",
            autoClose: 2500,
            theme: "dark",
          }
        );
      }
      confirm({
        title:
          "Are you sure you want to update qr card under this user?",
        icon: <ExclamationCircleFilled />,
        content: "This will update the qr of all cards under this user.",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        async onOk() {
          try {
            // const { data } = await (type ==="card" ? bukLocationClear(viewId._id) : type ==="ContactCard" ? singleContactCardLocClear(id) : singleReviewLocClear(id))
            const { data } = await bulkQrUpdateUserCard(viewId._id);
            // console.log(data);
  
            if (data.success) {
              toast.success(data.message ||"Qr updated successfully", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              // Optionally refresh your data here
              // setTrigger(!trigger); // If you're using a trigger for refetch
            }
          } catch (error) {
            console.error("Error clearing location:", error);
            toast.error(
              error?.response?.data?.message || "Error clearing location data",
              {
                position: "top-right",
                autoClose: 2500,
                theme: "dark",
              }
            );
          }
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };

  const personalDetails = [
    { name: 'Name', data: drawerData.name },
    { name: 'Status', data: cardStatus },
    { name: 'Email', data: drawerData.email },
    { name: 'Phone', data: drawerData.phone },
    { name: 'Card Limit', data: drawerData.cardLimit + ' cards' },
    { name: 'Used Card ', data: cards + ' cards' },
    { name: 'Remaning Card ', data: drawerData.cardLimit - cards + ' cards' ,style:result >= 70 ? 'bg-red-500 rounded-xl px-2 text-white' : '' },
    { name: 'Created Date', data: Moment(drawerData.createdDate).format("Do MMM YYYY") },
    { name: 'Expiry Date', data: drawerData.expiryDate ? Moment(ExpiryDate).format("Do MMM YYYY") : '' },
    { name: 'Validity', data: drawerData.expiryDate ? drawerData.expiryDate + " Days" : '' },
    { name: 'Remaining Days', data: drawerData.expiryDate ? (diffDays < 0 ? 'Expired' : diffDays + " Days") : '', style: diffDays < 5 ? 'bg-red-500 rounded-xl px-2 text-white' : '' },
  ]

  // console.log(drawerData, 'drawerData');
  

  return (
    <div>

      <Drawer
        width={550}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}

      >

        <Spin spinning={loader}>

          <div className="flex justify-between">
            <p
              className="site-description-item-profile-p text-2xl font-bold"
              style={{
                marginBottom: 24,
              }}
            >
              Details
            </p>
            {drawerData?.adminID && (
              <div className="flex gap-2">

                   <Button
                   className="w-30 "
                   onClick={showMoveModal}
                 >
                   Move To Other Admin
                 </Button>
                 {makeNormalUserButton}

              </div>


            )}
            {users ?
              (!drawerData.adminID ?
                <div>
     
       {moveUnderAdminButton}
       
                  <Button
                    className="text-white bg-red-600 hover:bg-red-700 w-28"
                    onClick={showDeleteConfirm}
                    loading={delLoder}
                  >
                    Delete
                  </Button>
                  <Button
                    className="text-white bg-slate-600 hover:bg-slate-700 ml-2 w-28 "
                    onClick={showDeactiveConfirm}
                    loading={statuslLoder}
                  >
                    {cardStatus === "blocked" ? "Unblock" : "Block"}
                  </Button>
                </div> : ''
              )
              :
              <div>
       
                <Button
                  className="text-white bg-red-600 hover:bg-red-700 w-28"
                  onClick={showDeleteConfirm}
                  loading={delLoder}
                >
                  Delete
                </Button>
                <Button
                  className="text-white bg-slate-600 hover:bg-slate-700 ml-2 w-28 "
                  onClick={showDeactiveConfirm}
                  loading={statuslLoder}
                >
                  {cardStatus === "blocked" ? "Unblock" : "Block"}
                </Button>
                <Button
                  onClick={handleSubmit}
                  type="button"
                  className="text-white bg-orange-600 hover:bg-orange-700 ml-2 w-28 "
                >
                  Edit
                </Button>
              </div>
            }
          </div>
          {/* <Avatar shape="square" size={80} src={drawerData?.profileImage ? drawerData?.profileImage : defaultProfileImage} /> */}
          <img className="w-40" src={drawerData?.profileImage ? drawerData?.profileImage : defaultProfileImage} alt="" />
          <div className="flex gap-2">
             {/* {drawerData?.adminID && (

            )}  */}
     
                <Button
                      className=" hover:bg-red-700 mt-2"
                      onClick={() => handleClearLoc()}
                      type="primary" danger
                      >
                      Clear Location
                    </Button>
                <Button
                      className="mt-2"
                      onClick={() => handleBulkQrUpdate()}
                      // type="primary" 
                      >
                      All Qr Update
                    </Button>
                      </div>

          {/* <p className="site-description-item-profile-p font-bold text-lg mt-3">Personal</p> */}
          <div className="grid grid-cols-2 gap-3 mt-3">
            {personalDetails.map((obj, index) => {
              return (
                <div key={index} className="mt-3">
                  {obj.data ?
                    <div className="flex ">
                      <p className="font-bold text-md mr-2">{obj.name} : </p>
                      <p className={obj.style} > {obj.data}</p>
                    </div>
                    : ''
                  }
                </div>
              )
            })}
          </div>

        </Spin>

      </Drawer>
      {moveModal}

      <ToastContainer />
    </div>
  );
}

export default UserDrawer;

import React, { useEffect, useState } from "react";
import { Drawer, Modal, Spin, Button } from "antd";
// import {
//   DeactiveCard,
//   DeleteCard,
//   getSigleCardData,
// } from "../../../api/AdminRequest";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
// import { editData } from "../../../Redux/Actions/CardAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  adminBlock,
  adminCardCounts,
  adminCards,
  adminDelete,
  bukLocationClear,
  updateQrAdminUser,
  userBlock,
  userCards,
  userDelete,
} from "../../api/AdminRequest";
import Moment from "moment";

const { confirm } = Modal;
const defaultProfileImage =
  "https://zeeqr-files.s3.ap-south-1.amazonaws.com/assets/defaultProfile.jpg";

function DrawerBox({ onClose, viewId, open, setChange, users }) {
  // const dispatch = useDispatch();
  // console.log(viewId, 'llllllllllllllllllllll');
  const navigate = useNavigate();
  const [drawerData, setDrawerData] = useState("");
  const [cardStatus, setCardStatus] = useState("");
  const [loader, setLoader] = useState(true);
  const [delLoder, setDelLoder] = useState(false);
  const [statuslLoder, setStatuslLoder] = useState(false);
  // const [cards, setCards] = useState('')
  // const [reviewCards, setReviewCards] = useState('')
  // const [contactCards, setConatactCards] = useState('')
  // const [serviceManager, setServiceManager] = useState('')
  // const [employee, setEmployee] = useState('')
  const [usedCards, setUsedCards] = useState();

  console.log(users, "viewId");

  useEffect(() => {
    const singleBookedCards = async () => {
      try {
        setLoader(true);
        // const { data } = users ? await userCards(viewId._id) : await adminCards(viewId._id);
        const { data } = users
          ? await userCards(viewId._id)
          : await adminCardCounts(viewId._id);
        console.log(data, "datas");
        if (data.success) {
          // setCards(data.card.length);
          // setReviewCards(data.reviewCard.length)
          // setConatactCards(data.contactCard.length)
          // setServiceManager(data.ServiceManager.length)
          // setEmployee(data.Employee.length)
          if (users) {
            setUsedCards({
              businessCard: data.card.length,
              reviewCard: data.reviewCard.length,
              serviceMangerLimit: data.ServiceManager.length,
              employeeLimit: data.Employee.length,
              contactCard: data.contactCard.length,
            });
          } else {
            setUsedCards({
              businessCard: data.businessCardCount,
              reviewCard: data.reviewCardCount,
              serviceManger: data.serviceManagerCount,
              employee: data.EmployeesCount,
              contactCard: data.contactCardCount,
              tableTalker:data.tableTakerCount
            });
          }

          setDrawerData(viewId);
          setCardStatus(viewId.status);
          setLoader(false);
        }

        // }
      } catch (error) {
        console.log(error);
      }
    };
    singleBookedCards();
  }, [viewId]);
  console.log(usedCards,"user cards");
  

  const handleSubmit = () => {
    // const id = drawerData._id
    navigate("/edit-admin", { state: { drawerData } });
  };

  const handleDeactive = async () => {
    try {
      setStatuslLoder(true);
      const { data } = users
        ? await userBlock(drawerData._id)
        : await adminBlock(drawerData._id);
      console.log(data);
      if (data.block) {
        console.log("sucess delete");
        setCardStatus("blocked");
        setStatuslLoder(false);
      }
      if (data.active) {
        setCardStatus("active");
        setStatuslLoder(false);
      }
      setChange(Date.now());
    } catch (error) {
      console.log(error);
      toast(error.response.data.message);
    }
  };

  const handleDelete = async () => {
    try {
      setDelLoder(true);
      const { data } = users
        ? await userDelete(drawerData._id)
        : await adminDelete(drawerData._id);
      console.log(data);
      if (data.delete) {
        console.log("sucess delete");
        setChange(Date.now());
        onClose();
        setDelLoder(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDeactiveConfirm = () => {
    confirm({
      title: `${
        cardStatus === "active"
          ? "Are you sure to Block "
          : "Are you sure to Active"
      }`,
      icon: <ExclamationCircleFilled />,
      content: `${
        cardStatus === "active"
          ? "if you block, all the profile also get blocked. "
          : "if you activate, all the profile also get activate."
      }`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeactive();
        // { cardStatus === "active" ? setCardStatus("deactive") : setCardStatus("active") }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete?",
      icon: <ExclamationCircleFilled />,
      // content: `${admin ? 'if you delete the card the user also get deleted.' : ' }` ,
      content: `if you delete, all the profile also get deleted.`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const ExpiryDate = new Date(
    Moment(drawerData.createdDate) + drawerData.expiryDate * 24 * 60 * 60 * 1000
  );
  const currentDate = Moment(Date.now()).format("MM DD YYYY");
  const ExpriyDATE = Moment(ExpiryDate).format("MM DD YYYY");
  // console.log(currentDate, 'currwent ');
  // console.log(ExpriyDATE, 'EPIRY ');
  var diffDays = Moment(ExpriyDATE).diff(currentDate, "days");
  // console.log(diffDays,'llllllllllllllldsayssssssssssssssssss');

  // const date1 = new Date(currentDate);
  // const date2 = new Date(ExpriyDATE);
  // const diffTime = Math.abs(date2 - date1);
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // console.log(diffTime,'exxxxxxxxxxxxxxxxxxxxxxxx');

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }
  // const result = percentage(cards, drawerData.cardLimit)

  const handleClearLoc = (type) => {
    if(!viewId._id){
      return toast.error(
        "No id found",
        {
          position: "top-right",
          autoClose: 2500,
          theme: "dark",
        }
      );
    }
    confirm({
      title:
        "Are you sure you want to clear all the location data under this admin?",
      icon: <ExclamationCircleFilled />,
      content: "This will clear all location history and reset tap count to 0.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          // const { data } = await (type ==="card" ? bukLocationClear(viewId._id) : type ==="ContactCard" ? singleContactCardLocClear(id) : singleReviewLocClear(id))
          const { data } = await bukLocationClear(viewId._id);
          console.log(data);

          if (data.success) {
            toast.success("Location data cleared successfully", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            // Optionally refresh your data here
            // setTrigger(!trigger); // If you're using a trigger for refetch
          }
        } catch (error) {
          console.error("Error clearing location:", error);
          toast.error(
            error?.response?.data?.message || "Error clearing location data",
            {
              position: "top-right",
              autoClose: 2500,
              theme: "dark",
            }
          );
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const handleQrUpdate = (type) => {
    if(!viewId._id){
      return toast.error(
        "No id found",
        {
          position: "top-right",
          autoClose: 2500,
          theme: "dark",
        }
      );
    }
    confirm({
      title:
        "Are you sure you want update the qr code of all the users under this admin?",
      icon: <ExclamationCircleFilled />,
      content: "This will change the qr code of all the users under this admin",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          // const { data } = await (type ==="card" ? bukLocationClear(viewId._id) : type ==="ContactCard" ? singleContactCardLocClear(id) : singleReviewLocClear(id))
          const { data } = await updateQrAdminUser(viewId._id);
          console.log(data,"updated");

          if (data.success) {
            toast.success( data.message || "Updated successfully", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            // Optionally refresh your data here
            // setTrigger(!trigger); // If you're using a trigger for refetch
          }
        } catch (error) {
          console.error("Error clearing location:", error);
          toast.error(
            error?.response?.data?.message || "Error clearing location data",
            {
              position: "top-right",
              autoClose: 2500,
              theme: "dark",
            }
          );
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const personalDetails = [
    { name: "Name", data: drawerData.name },
    { name: "Status", data: cardStatus },
    { name: "Email", data: drawerData.email },
    { name: "Phone", data: drawerData.phone },
    // { name: 'Card Limit', data: drawerData.cardLimit + ' cards' },
    // { name: 'Used Card ', data: cards + ' cards' },
    // { name: 'Remaning Card ', data: drawerData.cardLimit - cards + ' cards' ,style:result >= 70 ? 'bg-red-500 rounded-xl px-2 text-white' : '' },
    {
      name: "Created Date",
      data: Moment(drawerData.createdDate).format("Do MMM YYYY"),
    },
    {
      name: "Expiry Date",
      data: drawerData.expiryDate
        ? Moment(ExpiryDate).format("Do MMM YYYY")
        : "",
    },
    {
      name: "Validity",
      data: drawerData.expiryDate ? drawerData.expiryDate + " Days" : "",
    },
    {
      name: "Remaining Days",
      data: drawerData.expiryDate
        ? diffDays < 0
          ? "Expired"
          : diffDays + " Days"
        : "",
      style: diffDays < 5 ? "bg-red-500 rounded-xl px-2 text-white" : "",
    },
  ];
  // console.log(usedCards,"userCqards");
  

  return (
    <div>

      <Drawer
        width={550}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
      >
        <Spin spinning={loader}>
          <div className="flex justify-between">
            <p
              className="site-description-item-profile-p text-2xl font-bold"
              style={{
                marginBottom: 24,
              }}
            >
              Details
            </p>
            {users ? (
              !drawerData.adminID ? (
                <div>
                  <Button
                    className="text-white bg-red-600 hover:bg-red-700 w-28"
                    onClick={showDeleteConfirm}
                    loading={delLoder}
                  >
                    Delete
                  </Button>
                  <Button
                    className="text-white bg-slate-600 hover:bg-slate-700 ml-2 w-28 "
                    onClick={showDeactiveConfirm}
                    loading={statuslLoder}
                  >
                    {cardStatus === "blocked" ? "Unblock" : "Block"}
                  </Button>
                </div>
              ) : (
                ""
              )
            ) : (
              <>
                <div>
                  <Button
                    className="text-white bg-red-600 hover:bg-red-700 w-28"
                    onClick={showDeleteConfirm}
                    loading={delLoder}
                  >
                    Delete 
                  </Button>
                  <Button
                    className="text-white bg-slate-600 hover:bg-slate-700 ml-2 w-28 "
                    onClick={showDeactiveConfirm}
                    loading={statuslLoder}
                  >
                    {cardStatus === "blocked" ? "Unblock" : "Block"}
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    type="button"
                    className="text-white bg-orange-600 hover:bg-orange-700 ml-2 w-28 "
                  >
                    Edit
                  </Button>
                  <div></div>
                </div>
              </>
            )}
          </div>
          {/* <Avatar shape="square" size={80} src={drawerData?.profileImage ? drawerData?.profileImage : defaultProfileImage} /> */}
          <img
            className="w-40"
            src={
              drawerData?.profileImage
                ? drawerData?.profileImage
                : defaultProfileImage
            }
            alt=""
          />
          <div className="flex gap-2">
          <Button
            className=" hover:bg-red-700 mt-2"
            onClick={() => handleClearLoc()}
            type="primary" danger
          >
            Clear Location
          </Button>
         
  <Button
  className="  mt-2"
  onClick={() => handleQrUpdate()
  }
  // type="primary" 
>
  Update QR
</Button>
          
        

          </div>
       

          {/* <p className="site-description-item-profile-p font-bold text-lg mt-3">Personal</p> */}
          <div className="grid grid-cols-2 gap-3 mt-3">
            {personalDetails.map((obj, index) => {
              return (
                <div key={index} className="mt-3">
                  {obj.data ? (
                    <div className="flex ">
                      <p className="font-bold text-md mr-2">{obj.name} : </p>
                      <p className={obj.style}> {obj.data}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
          <div className="grid grid-cols-2 gap-3">
            <div>
              <h1 className="mt-3 mb-1 font-bold">Card Limits</h1>
              {drawerData?.features?.map((item, index) => (
                <div key={index} className="flex ">
                  {item?.type === "feedbackCard" ? (
                    <div>
                      <p className="font-semibold">{item?.type} </p>
                      <p>
                        <span> Service Manger Limit :</span>{" "}
                        {item?.serviceMangerLimit} cards
                      </p>
                      <p>
                        <span> Employee Limit :</span> {item?.employeeLimit}{" "}
                        cards
                      </p>
                    </div>
                  ) : (
                    <p>
                      <span className="font-semibold"> {item?.type} :</span>{" "}
                      {item.limit} cards
                    </p>
                  )}
                </div>
              ))}
            </div>

            <div>
              <h1 className="mt-3 mb-1 font-bold">Used Card</h1>
              {drawerData?.features?.map((item, index) => (
                <div key={index} className="flex ">
                  {item?.type === "feedbackCard" ? (
                    <div>
                      <p className="font-semibold">{item?.type} </p>
                      <p>
                        <span> Service Manger Limit :</span>{" "}
                        {usedCards?.serviceMangerLimit} cards
                      </p>
                      <p>
                        <span> Employee Limit :</span>{" "}
                        {usedCards?.employeeLimit} cards
                      </p>
                    </div>
                  ) : (
                    <p>
                      <span className="font-semibold"> {item?.type} :</span>{" "}
                      {usedCards[item?.type]} cards
                    </p>
                  )}
                </div>
              ))}
            </div>
            <div>
              <h1 className="mt-3 mb-1 font-bold">Remaning Card</h1>
              {drawerData?.features?.map((item, index) => (
                <div key={index} className="flex ">
                  {item?.type === "feedbackCard" ? (
                    <div>
                      <p className="font-semibold">{item?.type} </p>
                      <p>
                        <span> Service Manger Limit :</span>{" "}
                        {item?.serviceMangerLimit -
                          usedCards?.serviceMangerLimit}{" "}
                        cards
                      </p>
                      <p>
                        <span> Employee Limit :</span>{" "}
                        {item?.employeeLimit - usedCards?.employeeLimit} cards
                      </p>
                    </div>
                  ) : (
                    <p>
                      <span className="font-semibold"> {item?.type} :</span>{" "}
                      {item.limit - usedCards[item?.type]} cards
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Spin>
      </Drawer>

      <ToastContainer />
    </div>
  );
}

export default DrawerBox;

import React, { useEffect, useState } from "react";
import { Drawer, Spin, } from "antd";
import { ToastContainer, } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";
// import { ExclamationCircleFilled } from "@ant-design/icons";
// import { cancelOrder } from "../../../api/AdminRequest";
// import SelectZeeqrCards from "../selectCard/SelectZeeqrCards";
import rotateimg from '../../assests/rotate.png';
import Moment from 'moment'
import SelectCardName from "../services/materailsDetail/SelectCardName";
import SelectBusinessQRCard from "../services/BusinessQRCard/SelectBusinessQRCard";
import SelectReviewCard from "../services/reviewQRCard/SelectReviewCard";
import { getMaterialDetails } from "../../api/AdminRequest";

// const { confirm } = Modal;
const digitalInterfaceImageUrl = "https://zeeqr2.s3.ap-south-1.amazonaws.com/DigitalThemes/theme-classic.png"


function OrderDrawer({ onClose, viewId, open, setChanges }) {
  // const navigate = useNavigate();
  const [drawerData, setDrawerData] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [loader, setLoader] = useState(true)
  // const [rotate, setRotate] = useState(false)
  const [cards, setCards] = useState([])
  const [rotate, setRotate] = useState(false)



  const [productDetails, setProductDetails] = useState({})

  useEffect(() => {
    const singleBookedCards = async () => {
      try {

        // console.log(viewId);
        setDrawerData(viewId);
        setOrderStatus(viewId.status)
        setCards(viewId?.card)

        if (viewId.cardType === 'reviewQRCard' || viewId.cardType === 'BusinessQRCard') {

          const { data } = await getMaterialDetails(viewId?.card?.productID?.materialID ? viewId?.card?.productID?.materialID : '654f1c404306d8f388ce5531');
          // console.log(data, '88888  ');
          if (data.success) {
            setProductDetails(data.materials)
          }
        }

        setLoader(false)
      } catch (error) {
        console.log(error);
      }
    };
    singleBookedCards();
  }, [viewId]);
  // console.log(cards, '||||||||||||||||||||||');
  console.log(drawerData,"drawerData");
  

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  // const [error, setError] = useState({});
  // const initialValues = { reason: '', comment: '' }

  // const [formValues, setFormValues] = useState(initialValues)

  // const handleChange = (e) => {
  //   const { name, value } = e.target
  //   setFormValues({ ...formValues, [name]: value })
  //   // console.log(formValues)
  // }
  // const handleOk = async () => {

  //   const id = drawerData._id
  //   try {
  //     const errors = validateForm(formValues)
  //     setError(errors)
  //     if (Object.keys(errors).length === 0) {
  //       setLoader(true)
  //       const { data } = await cancelOrder(id, formValues)
  //       console.log(data);
  //       if (data.success) {
  //         setIsModalOpen(false);
  //         setChanges(Date.now())
  //         setOrderStatus('cancelled')
  //         setLoader(false)
  //         toast.success('Order Cancelled Successfully', {
  //           position: "top-right",
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "dark",
  //         });

  //       }
  //     }
  //   } catch (error) {

  //   }
  // };
  // const validateForm = (data) => {
  //   const error = {};
  //   if (!data.reason) {
  //     error.reason = "full name required"
  //   }
  //   if (!data.comment) {
  //     error.comment = "company name required"
  //   }
  //   return error;
  // }
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  // const showDeleteConfirm = () => {
  //   confirm({
  //     title: "Are you sure to cancel the order?",
  //     icon: <ExclamationCircleFilled />,
  //     content: "If you cancel now, you may not be able to avail this deal again.Do you still want to cancel ?",
  //     okText: "Cancel Order",
  //     okType: "danger",
  //     cancelText: "Back",
  //     onOk() {
  //       // handleDelete();
  //       setIsModalOpen(true);
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };

  // const handleTrack = () => {

  // }

  // const Rotation = () => {
  //   setRotate(!rotate)
  // }
  // setInterval(()=>{
  //     Rotation()
  // },3000)

  // let materail = []
  // materail = drawerData?.card
  // console.log(materail, '??????????????????????');

  const Details = [
    { name: 'Full Name', data: drawerData.name },
    { name: 'Status', data: orderStatus },
    { name: 'Email', data: drawerData.email },
    { name: 'Phone', data: drawerData.phone },
    { name: 'Address', data: drawerData.streetaddress },
    { name: 'Order Date', data: Moment(drawerData.createdDate).format("Do MMM YYYY") },
    { name: 'Country', data: drawerData.country },
    { name: 'State', data: drawerData.state },
    { name: 'Town/City', data: drawerData.towncity },
    { name: 'Land Mark', data: drawerData.landmark },
    { name: 'Pincode', data: drawerData.postcode },
    { name: 'Order Note', data: drawerData.ordernote },
    { name: 'Cooupon Code', data: drawerData?.couponCode ? drawerData?.couponCode :  drawerData?.card?.couponCode },
  ]

  const paymentDetails = [
    { name: 'Status', data: drawerData.payment?.paymentStatus },
    { name: 'Amount', data:` ${drawerData?.payment?.currency}  ${drawerData.payment?.amount}` },
    { name: 'Repayment', data: drawerData.payment?.repayment },
    { name: 'Date', data: Moment(drawerData.payment?.updateDateTime).format("Do MMM YYYY, h:mm a") },
  ]

  const paymentMethod = drawerData?.payment?.paymentMethod


  const cardDetails = {
    name: cards?.cardName,
    tagline: cards?.tagline
  }

  const Rotation = () => {
    setRotate(!rotate)
  }

  return (
    <div>
      <Drawer
        width={640}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
      >
        <Spin spinning={loader}>
          <div className="flex justify-between">
            <p
              className="site-description-item-profile-p text-xl font-bold"
              style={{
                marginBottom: 24,
              }}
            >
              Details
            </p>
            {/* {orderStatus === 'cancelled' ? '' :
              <div>
                {orderStatus === 'processing' ?
                  <Button
                    className="text-white bg-red-600 hover:bg-red-700 w-28"
                    onClick={showDeleteConfirm}
                  // loading={delLoder}
                  >
                    Cancel
                  </Button>
                  : ''
                }
                <Button
                  className="text-white bg-slate-600 hover:bg-slate-700 ml-2 w-28 "
                  onClick={handleTrack}
                // loading={statuslLoder}
                >
                  Track
                </Button>
              </div>
            } */}

          </div>


          <div className='grid grid-cols-2 gap-3 mt-3'>
            {/* 
            <div className="w-[350px] ml-2" >
              <SelectZeeqrCards rotate={rotate} material={cards?.material} materialcolor={cards?.materialcolor} materialFavoriteColor={cards?.materialFavoriteColor} imgPre={cards?.cardLogo} formValues={cardDetails} textColor={cards?.textColor} cardColor={cards?.cardColor} />
              <div className="flex justify-center item-center mt-5 mb-5">
                <img className='cursor-pointer' src={rotateimg} onClick={Rotation} alt=''></img>
              </div>
            </div>
            <div className=" flex flex-col w-60  justify-center ml-16">
              <p className='text-xl text-slate-600   font-bold '>{cards?.material}</p>

              <p className='text-slate-500 text-sm' >2x {cards?.materialcolor === "MBlack" || cards?.materialcolor === "PBlack" ? 'Matte Black' : cards?.materialcolor === "MWhite" || cards?.materialcolor === "PWhite" ? 'Matte White' : cards?.materialcolor === "MGold" ? '24K Gold' : 'Customized Card'} -</p>
              <p className='text-slate-500 text-sm' >2x {cards?.materialFavoriteColor === 'BWhite' ? 'White on Matte Black' :
                cards?.materialFavoriteColor === 'BGray' ? 'Gray on Matte Black' :
                  cards?.materialFavoriteColor === 'Gold' ? 'Gold' :
                    cards?.materialFavoriteColor === 'Silver' ? 'Silver' :
                      cards?.materialFavoriteColor === 'Foil' ? 'Foil' :
                        cards?.materialFavoriteColor === 'WBlack' ? 'Black on Matte White' :
                          cards?.materialFavoriteColor === 'WBlue' ? 'Blue on Matte White' :
                            cards?.materialFavoriteColor === 'BGoldY' ? 'Gold on Metal Black' :
                              cards?.materialFavoriteColor === 'BGray' ? 'Gray on Metal Black' :
                                cards?.materialFavoriteColor === 'BGoldY' ? 'Gold on Metal White' :
                                  cards?.materialFavoriteColor === 'WGray' ? 'Gray on Metal White' :
                                    cards?.materialFavoriteColor === 'MGold' ? '24 Gold on laser printed' :
                                      'Customized Text'} -</p>
            </div> */}

            <div className="w-[350px] ml-2" >
              {/* <SelectReviewCard rotate={rotate} material={material} materialcolor={materialcolor} materialFavoriteColor={materialFavoriteColor} textColor={textColor} cardColor={cardColor} productDetails={productDetails} reviewType={materails?.reviewType}  /> */}
              {drawerData?.cardType === 'reviewQRCard' ?
                <div className="flex justify-center">
                  <div className='h-[350px]  w-[222px] flex justify-center'>
                    <SelectReviewCard rotate={rotate} material={cards?.material} materialcolor={cards?.materialcolor} materialFavoriteColor={cards?.materialFavoriteColor} textColor={cards?.textColor} cardColor={cards?.cardColor} reviewType={cards?.reviewType} productDetails={productDetails} />
                  </div>
                </div>
                : drawerData?.cardType === 'BusinessQRCard' ?
                  <div className='w-[300px] sm:w-[350px]'>
                    <SelectBusinessQRCard rotate={rotate} material={cards?.material} materialcolor={cards?.materialcolor} materialFavoriteColor={cards?.materialFavoriteColor} textColor={cards?.textColor} cardColor={cards?.cardColor} productDetails={productDetails} />
                  </div>
                  :
                  <div className="h-52 w-32 overflow-hidden rounded-xl shadow-xl border-2">
                    <img src={cards?.productID ? cards?.productID?.imageUrl : digitalInterfaceImageUrl} alt="" srcset="" />
                  </div>
              }

              {drawerData?.cardType === 'reviewQRCard' || drawerData?.cardType === 'BusinessQRCard' ?
                <div className="flex justify-center item-center mt-5 mb-5">
                  <img className='cursor-pointer' src={rotateimg} onClick={Rotation} alt=''></img>
                </div>
                : ''
              }
            </div>
            {drawerData?.cardType === 'reviewQRCard' || drawerData?.cardType === 'BusinessQRCard' ?
              <div className=" flex flex-col w-60  justify-center ml-20">

                <p className='text-xl text-slate-600   font-bold '>{cards?.material}</p>
                <SelectCardName material={cards?.material} materialcolor={cards?.materialcolor} materialFavoriteColor={cards?.materialFavoriteColor} productDetails={productDetails} />

              </div>
              : ''
            }

          </div>


          <h1 className="text-lg font-bold mt-5">Shipping Details</h1>
          <div className="grid grid-cols-2 mt-2">
            {Details.map((obj) => {
              return (
                <div className="mt-3">
                  {obj.data ?
                    <div className="flex ">
                      <p className="font-medium mr-2">{obj.name} : </p>
                      <p  > {obj.data}</p>
                    </div>
                    : ''
                  }
                </div>
              )
            })}
          </div>

          <h1 className="text-lg font-bold mt-5">Payment Details</h1>
          {paymentDetails?.map((obj) => {
            return (
              <div className="mt-3">
                {obj.data ?
                  <div className="flex ">
                    <p className="font-medium mr-2">{obj.name} : </p>
                    <p  > {obj.data}</p>
                  </div>
                  : ''
                }
              </div>
            )
          })}


          {paymentMethod ?
            <>
              <h1 className="text-lg font-bold mt-5">Payment Details</h1>
              {Object?.keys(paymentMethod)?.map(key => (
                <div className="flex mt-3" key={key}>
                  <p className="capitalize font-medium mr-2"> {key} :</p>  <p> {paymentMethod[key]} </p>
                </div>
              ))
              }
            </>
            : ''
          }




          {/* <Modal title="Confirm Cancel" confirmLoading={loader} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            okText="Submit Request" cancelText="Back" closable={false} maskClosable={false}
          >
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label htmlFor="usereasonrname" className="block text-sm font-medium leading-6 text-gray-900">
                  Reason
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="reason"
                    id="reason"
                    autoComplete="reason"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder=" reason for cancelling"
                    value={formValues.reason} onChange={handleChange}
                  />
                  <p className='text-red-500'>{error.reason}</p>
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  Comment
                </label>
                <div className="mt-2">
                  <textarea
                    id="comment"
                    name="comment"
                    placeholder=" Write about why your are cancelling the product"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={''}
                    value={formValues.comment} onChange={handleChange}
                  />
                  <p className='text-red-500'>{error.comment}</p>
                </div>
              </div>
            </div>
          </Modal> */}

        </Spin>
        <ToastContainer />
      </Drawer>

    </div>
  );
}

export default OrderDrawer;

import React, { useState } from 'react';
import { Card, Form, Input, Select, Button, Alert, message } from 'antd';
import { UserOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { changeAdminPassword, changeUserPassword } from '../../api/AdminRequest';

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [userType, setUserType] = useState("user");
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      
      // Prepare the request data
      const requestData = {
        email: values.email,
        newPassword: values.newPassword
      };

      let response;
      
      if (values.userType === 'admin') {
      
        response = await changeAdminPassword(requestData);
      } else if (values.userType === 'user'){

        response = await changeUserPassword(requestData);
      }else {
        return message.error('Select valid user type')
      }

      if (response.data.success) {
        message.success('Password changed successfully');
        form.resetFields(); // Reset form after successful submission
      } else {
        message.error(response.data.message || 'Failed to change password');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      message.error(
        error.response?.data?.message || 
        'Failed to change password. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject('Please enter a password');
    }
    if (value.length < 8) {
      return Promise.reject('Password must be at least 8 characters long');
    }
    return Promise.resolve();
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <Card 
        title={
          <div className="text-center text-xl font-bold">
            Change Password of {userType}
          </div>
        }
        className="w-full shadow-lg"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ userType: 'user' }} // Set initial values here
          
        >
          <Form.Item
            name="userType"
            label="Select User Type"
            rules={[{ required: true, message: 'Please select a user type' }]}
          >
            <Select
              onChange={(value) => setUserType(value)}
              placeholder="Select user type"
            >
              <Select.Option value="user">
                <div className="flex items-center gap-2">
                  <UserOutlined />
                  <span>User</span>
                </div>
              </Select.Option>
              <Select.Option value="admin">
                <div className="flex items-center gap-2">
                  <SafetyCertificateOutlined />
                  <span>Admin</span>
                </div>
              </Select.Option>
            </Select>
          </Form.Item>

          {userType && (
            <Alert
              className="mb-4"
              message={
                <div className="flex items-center  gap-2">
                  {userType === 'admin' ? (
                    <>
                      <SafetyCertificateOutlined />
                      <span>Changing <span className='text-md font-bold'>ADMIN</span> Password</span>
                    </>
                  ) : (
                    <>
                      <UserOutlined />
                      <span>Changing <span className='text-md font-bold'>USER</span> Password</span>
                    </>
                  )}
                </div>
              }
              type="info"
              showIcon={false}
            />
          )}

          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              { required: true, message: 'Please enter email' },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input 
              prefix={<UserOutlined />}
              placeholder="Enter email"
            />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              { validator: validatePassword }
            ]}
          >
            <Input.Password 
              placeholder="Enter new password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['newPassword']}
            rules={[
              { required: true, message: 'Please confirm password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Passwords do not match');
                },
              }),
            ]}
          >
            <Input.Password 
              placeholder="Confirm new password"
            />
          </Form.Item>

          {/* <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit"
              className="w-full bg-blue-500 hover:bg-blue-600"
            >
              Change Password
            </Button>
          </Form.Item> */}
           <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit"
              className="w-full bg-blue-500 hover:bg-blue-600"
              loading={loading}
            >
              {loading ? 'Changing Password...' : 'Change Password'}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ChangePassword;

import React, { useEffect, useRef, useState } from "react";
import {  Button, Table, Input, Space, Row, Col, Typography, Tabs, Modal, } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, ExclamationCircleFilled, SearchOutlined } from "@ant-design/icons";
import { adminBusinessCards, adminContactCards, adminReviewCards, adminTableTaker, singleContactCardLocClear, singleLocClear, singleQrUpdate, singleReviewLocClear, userCards, userContactCards, userReviewCards, userTableTaker } from "../../api/AdminRequest";
import Highlighter from 'react-highlight-words';
import CardDetailsDrawer from "../admin/cardsDrawer/CardDetailsDrawer";
import ContactCardDrawer from "../drawer/ContactCardDrawer";
import Moment from 'moment'
import { toast } from "react-toastify";

const { Title } = Typography;
const { confirm } = Modal;

function Cards({ onCloseCard, openCard, setChange, users }) {
    //  const [cards,setCards]= useState()

    // useEffect(() => {
    //     const getCards = () => {

    //     }
    //     getCards()
    // })

    const [businessCards, setBusinessCards] = useState([])
    const [reviewCards, setReviewCards] = useState([])
    const [tableTaker, setTableTaker] = useState([])
    const [contactCards, setContactCards] = useState([])
    // const [drawerData, setDrawerData] = useState("");
    const [loader, setLoader] = useState(true)
    const [dataChanges, setDataChanges] = useState('')
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [viewId, setViewID] = useState('')
    const [open, setOpen] = useState(false);
    const [viewContact, setViewContact] = useState('')
    const [openContact, setOpenContact] = useState(false);
    const [tabIndex, setTabIndex] = useState('')


    const navigate = useNavigate()
    const { state } = useLocation();
    // console.log(state, '************************');
    const details = state?.details
    const status = state?.status
    const from = state?.from?.pathname
    // const cardViewId = state?._id




    const getBusinessCards = async () => {
        try {
            setLoader(true)
    
            const { data } = status === 'user' ? await userCards(details?._id) : await adminBusinessCards(details?._id);
            console.log(data, 'lllll');
            if (data.success) {
                if (data.card) {
                    setBusinessCards(data.card);
                }
                setLoader(false)

            }
        } catch (error) {
            console.log(error);
        }
    };
    const getReviewCards = async () => {
        try {
            setLoader(true)
            const { data } = status === 'user' ? await userReviewCards(details?._id) : await adminReviewCards(details?._id);
            // console.log(data, 'lllll');
            if (data.success) {
                if (data.reviewCard) {
                    setReviewCards(data.reviewCard);
                }
                setLoader(false)
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getTableTaker = async () => {
        try {
            setLoader(true)
            const { data } = status === 'user' ? await userTableTaker(details?._id) : await adminTableTaker(details?._id);
            // console.log(data, 'lllll');
            if (data.success) {
                if (data.tableTaker) {
                    setTableTaker(data.tableTaker);
                }
                setLoader(false)
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getContactCards = async () => {
        try {
            setLoader(true)
            const { data } = status === 'user' ? await userContactCards(details?._id) : await adminContactCards(details?._id);
            // console.log(data, 'lllll');
            if (data.success) {
                if (data.contactCard) {
                    setContactCards(data.contactCard);
                }
                setLoader(false)
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {

        if (tabIndex === '2') {
            getReviewCards()
        } else if (tabIndex === '3') {
            getTableTaker()
        
        } else if (tabIndex === '4') {
            getContactCards()
        } 
        else {
            getBusinessCards();
        }
    }, [details, dataChanges, tabIndex]);

    const showDrawer = async (ID) => {
        setViewID(ID)
        setOpen(true);

    };
    const showDrawerContact = async (ID) => {
        setViewContact(ID)
        setOpenContact(true);

    };


    const onClose = () => {
        setOpen(false);
        setOpenContact(false);

    };
    const handleClearLocation = (id, type) => {
      
      
        
        confirm({
            title: 'Are you sure you want to clear location data?',
            icon: <ExclamationCircleFilled />,
            content: 'This will clear all location history and reset tap count to 0.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    const { data } = await (type ==="card" ? singleLocClear(id) : type ==="ContactCard" ? singleContactCardLocClear(id) : singleReviewLocClear(id))
                        console.log(data);
                        
                    if (data.success) {
                        toast.success("Location data cleared successfully", {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                        // Optionally refresh your data here
                        // setTrigger(!trigger); // If you're using a trigger for refetch
                    }
                } catch (error) {
                    console.error('Error clearing location:', error);
                    toast.error(error?.response?.data?.message || "Error clearing location data", {
                        position: "top-right",
                        autoClose: 2500,
                        theme: "dark",
                    });
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const handleQrUpdate = (id, type) => {
        // if(!viewId._id){
        //   return toast.error(
        //     "No id found",
        //     {
        //       position: "top-right",
        //       autoClose: 2500,
        //       theme: "dark",
        //     }
        //   );
        // }
        confirm({
          title:
            "Are you sure you want update the qr code of this card?",
          icon: <ExclamationCircleFilled />,
          content: "This will change the qr code the ",
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          async onOk() {
            try {
              // const { data } = await (type ==="card" ? bukLocationClear(viewId._id) : type ==="ContactCard" ? singleContactCardLocClear(id) : singleReviewLocClear(id))
              const { data } = await singleQrUpdate(id);
              console.log(data,"updated");
    
              if (data.success) {
                toast.success( data.message || "Updated successfully", {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
                // Optionally refresh your data here
                // setTrigger(!trigger); // If you're using a trigger for refetch
              }
            } catch (error) {
              console.error("Error clearing location:", error);
              toast.error(
                error?.response?.data?.message || "Error clearing location data",
                {
                  position: "top-right",
                  autoClose: 2500,
                  theme: "dark",
                }
              );
            }
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      };


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        className='bg-blue-800'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            clearFilters && handleReset(clearFilters)
                            handleSearch(selectedKeys, confirm)
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            render: (text, record, index) => (businessCards.indexOf(record) + 1),

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Expiry Date',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                const ExpiryDate = new Date(Moment(_id.date) + _id.expire * 24 * 60 * 60 * 1000)

                return (
                    <p>
                        {Moment() < ExpiryDate ?
                            Moment(ExpiryDate).format("Do MMM YYYY")
                            :
                            <p className="text-sm text-red-500 font-bold">Expired</p>
                        }
                    </p>

                )
            }

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status === 'active' ? "Active" : "Blocked" },
        },
        {
            title: 'View',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showDrawer(_id)} >View</Button>
                )
            }

        },
        {
            title: 'Preview',
            dataIndex: '_id',
            key: '_id',
            render: (_id) => <Link to={process.env.REACT_APP_API_QR_CODE_BASE_URL + _id} target="_blank"> <Button className='w-24 bg-slate-400 hover:bg-slate-300' >  Preview </Button></Link>

        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <div className="flex gap-1">

                    <Button   className="text-red-500 border-red-500 border-[1px] font-semibold text-[12px]" onClick={() =>handleClearLocation(_id._id ,"card")
                    } >Location Clear</Button>
                    <Button   className="text-green-500 border-green-500 border-[1px] font-semibold text-[12px]" onClick={() => handleQrUpdate(_id._id,"card")
                    } >QR Update</Button>
                    </div>

                )
            }

        },

    ];
    const contactColumns = [
        {
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            render: (text, record, index) => (contactCards.indexOf(record) + 1),

        },
        {
            title: 'Name',
            dataIndex: 'personalDetails',
            key: 'personalDetails',
            render: (personalDetails) => <p>{personalDetails.name}</p>

        },

        {
            title: 'Email',
            dataIndex: 'personalDetails',
            key: 'personalDetails',
            render: (personalDetails) => <p>{personalDetails.email}</p>
        },
        
        {
            title: 'Expiry Date',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                const ExpiryDate = new Date(Moment(_id.createdDate) + _id.expire * 24 * 60 * 60 * 1000)

                return (
                    <p>
                        {Moment() < ExpiryDate ?
                            Moment(ExpiryDate).format("Do MMM YYYY")
                            :
                            <p className="text-sm text-red-500 font-bold">Expired</p>
                        }
                    </p>

                )
            }

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status === 'active' ? <p className=' text-center'>Active</p> : <p className='bg-red-500 px-1 text-white text-center rounded-xl'>Blocked</p> },

        },
        {
            title: 'View',
            dataIndex: 'status._id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showDrawerContact(_id)} >View</Button>
                )
            }

        },
     
        {
            title: 'Preview',
            dataIndex: '_id',
            key: '_id',
            render: (_id) => <Link to={process.env.REACT_APP_API_CONTACT_CARD_BASE_URL + _id} target="_blank"> <Button className='w-24 bg-slate-400 hover:bg-slate-300' >  Preview </Button></Link>

        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (_id) => {
                return (
                    <Button   className="text-red-500 border-red-500 border-[1px] font-semibold text-[12px]" onClick={() =>handleClearLocation(_id, "ContactCard")
                    } >Location Clear</Button>
                )
            }

        },

    ];
    const reviewColumns = [
        {
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            render: (text, record, index) => (reviewCards.indexOf(record) + 1),

        },
        {
            title: 'Name',
            dataIndex: 'userID',
            key: 'userID',
            render: (userID) => <p>{userID.name}</p>

        },
        {
            title: 'Email',
            dataIndex: 'userID',
            key: 'userID',
            render: (userID) => <p>{userID.email}</p>

        },
        {
            title: 'Review Url',
            dataIndex: 'reviewUrl',
            key: 'reviewUrl',
            ...getColumnSearchProps('reviewUrl'),
        },
        {
            title: 'Expiry Date',
            dataIndex: 'status._id',
            key: '_id',
            width: '12%',
            render: (status, _id) => {
                const ExpiryDate = new Date(Moment(_id.createdDate) + _id.expire * 24 * 60 * 60 * 1000)

                return (
                    <p>
                        {Moment() < ExpiryDate ?
                            Moment(ExpiryDate).format("Do MMM YYYY")
                            :
                            <p className="text-sm text-red-500 font-bold">Expired</p>
                        }
                    </p>

                )
            }

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status === 'active' ? <p className=' text-center'>Active</p> : <p className='bg-red-500 px-1 text-white text-center rounded-xl'>Blocked</p> },

        },
        // {
        //     title: 'View',
        //     dataIndex: 'status._id',
        //     key: '_id',
        //     render: (status, _id) => {
        //         return (
        //             <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showDrawer(_id)} >View</Button>
        //         )
        //     }

        // },
        {
            title: 'Preview',
            dataIndex: '_id',
            key: '_id',
            render: (_id) => <Link to={process.env.REACT_APP_API_REVIEW_QR_CODE_BASE_URL + _id} target="_blank"> <Button className='w-24 bg-slate-400 hover:bg-slate-300' >  Preview </Button></Link>

        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <Button   className="text-red-500 border-red-500 border-[1px] font-semibold text-[12px]" onClick={() =>handleClearLocation(_id._id, "reviewCard")
                    } >Location Clear</Button>
                )
            }

        },

    ];
    const TableTakerColumns = [
        {
            title: 'No.',
            dataIndex: '_id',
            key: 'index',
            render: (text, record, index) => (tableTaker.indexOf(record) + 1),

        },
        {
            title: 'Name',
            dataIndex: 'userID',
            key: 'userID',
            render: (userID) => <p>{userID.name}</p>

        },
        {
            title: 'Email',
            dataIndex: 'userID',
            key: 'userID',
            render: (userID) => <p>{userID.email}</p>

        },
        {
            title: 'Review Url',
            dataIndex: 'reviewUrl',
            key: 'reviewUrl',
            ...getColumnSearchProps('reviewUrl'),
        },
        {
            title: 'Expiry Date',
            dataIndex: 'status._id',
            key: '_id',
            width: '12%',
            render: (status, _id) => {
                const ExpiryDate = new Date(Moment(_id.createdDate) + _id.expire * 24 * 60 * 60 * 1000)

                return (
                    <p>
                        {Moment() < ExpiryDate ?
                            Moment(ExpiryDate).format("Do MMM YYYY")
                            :
                            <p className="text-sm text-red-500 font-bold">Expired</p>
                        }
                    </p>

                )
            }

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            // ...getColumnSearchProps('status'),
            render: (status) => { return status === 'active' ? <p className=' text-center'>Active</p> : <p className='bg-red-500 px-1 text-white text-center rounded-xl'>Blocked</p> },

        },
        // {
        //     title: 'View',
        //     dataIndex: 'status._id',
        //     key: '_id',
        //     render: (status, _id) => {
        //         return (
        //             <Button className='w-24 bg-slate-300 hover:bg-slate-200' onClick={() => showDrawer(_id)} >View</Button>
        //         )
        //     }

        // },
        {
            title: 'Preview',
            dataIndex: '_id',
            key: '_id',
            render: (_id) => <Link to={process.env.REACT_APP_API_REVIEW_QR_CODE_BASE_URL + _id} target="_blank"> <Button className='w-24 bg-slate-400 hover:bg-slate-300' >  Preview </Button></Link>

        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (status, _id) => {
                return (
                    <Button   className="text-red-500 border-red-500 border-[1px] font-semibold text-[12px]" onClick={() =>handleClearLocation(_id._id, "reviewCard")
                    } >Location Clear</Button>
                )
            }

        },

    ];

    const items = [
        {
            key: '1',
            label: `Business Card`,
            children: [
                <>
                    <Table columns={columns} loading={loader} dataSource={businessCards} pagination={businessCards.length > 10 ? true : false} />
                    <CardDetailsDrawer viewId={viewId} setChange={setDataChanges} open={open} onClose={onClose} />

                </>


            ],
        },
        {
            key: '2',
            label: `Review Card`,
            children: [
                <>
                    <Table columns={reviewColumns} loading={loader} dataSource={reviewCards} pagination={reviewCards.length > 10 ? true : false} />
                    <CardDetailsDrawer viewId={viewId} open={open} onClose={onClose} />
                </>

            ],
        },
        {
            key: '3',
            label: `Table Taker`,
            children: [
                <>
                    <Table columns={TableTakerColumns} loading={loader} dataSource={tableTaker} pagination={tableTaker.length > 10 ? true : false} />
                    <CardDetailsDrawer viewId={viewId} open={open} onClose={onClose} />
                </>

            ],
        },
        {
            key: '4',
            label: `Contact Card`,
            children: [
                <>
                    <Table columns={contactColumns} loading={loader} dataSource={contactCards} pagination={contactCards?.length > 10 ? true : false} />
                    <ContactCardDrawer viewId={viewContact} open={openContact} onClose={onClose} />
                </>
            ],
        },
    ];
    const onChange = (key) => {
        // console.log(key);
        setTabIndex(key)
    };

    const handelBack = () => {
        navigate(from, { replace: true })
    }
    return (
        <div>
            {/* <section class="py-1 bg-blueGray-50">
                <div class="w-full  mb-12 xl:mb-0 px-4 mx-auto mt-24">
                    <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded ">
                        <div class="rounded-t mb-0 px-4 py-3 border-0">
                            <div class="flex flex-wrap items-center">
                                <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <h3 class="font-semibold text-base text-blueGray-700">Page Visits</h3>
                                </div>
                                <div class="p-4">
                                    <label for="table-search" class="sr-only">Search</label>
                                    <div class="relative mt-1">
                                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg>
                                        </div>
                                        <input type="text" id="table-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items"/>
                                    </div>
                                </div>
                                <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                                    <button class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">See all</button>
                                </div>
                            </div>
                        </div>

                        <div class="block w-full overflow-x-auto">
                            <table class="items-center bg-transparent w-full border-collapse ">
                                <thead>
                                    <tr>
                                        <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                           NO.
                                        </th>
                                        <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                            Name
                                        </th>
                                        <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                            Address
                                        </th>
                                        <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                            Phone Number
                                        </th>
                                        <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                            Status
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                                           1
                                        </th>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                                            /argon/
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                            4,569
                                        </td>
                                        <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            340
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <i class="fas fa-arrow-up text-emerald-500 mr-4"></i>
                                            46,53%
                                        </td>
                                    </tr>
                                    
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
                <footer class="relative pt-8 pb-6 mt-16">
                    <div class="container mx-auto px-4">
                        <div class="flex flex-wrap items-center md:justify-between justify-center">
                            <div class="w-full md:w-6/12 px-4 mx-auto text-center">
                                <div class="text-sm text-blueGray-500 font-semibold py-1">
                                    Made with <Link to="https://www.creative-tim.com/product/notus-js" class="text-blueGray-500 hover:text-gray-800" rel='' target="_blank">Notus JS</Link> by <Link to="https://www.creative-tim.com" class="text-blueGray-500 hover:text-blueGray-800" target="_blank"> Creative Tim</Link>.
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </section> */}


            <Row gutter={16}>
                <Col span={12} className="flex">
                    <p className='text-md  mr-5 h-12  py-2 px-3 rounded-full border  font-medium cursor-pointer' onClick={handelBack}> <ArrowLeftOutlined className='text-xl' /> </p>

                    <Title level={2}>{details?.name} Cards</Title>

                </Col>
                <Col span={12} className='flex justify-end'>
                    <div>
                        <p><span className="font-semibold">ID :</span> {details?._id}</p>
                        <p><span className="font-semibold">Name :</span> {details?.name}</p>
                        <p><span className="font-semibold">Email :</span> {details?.email}</p>
                        <p><span className="font-semibold">Phone  :</span> {details?.phone}</p>
                    </div>

                </Col>
            </Row>

            <div className="flex flex-col justify-between mt-5">


                {/* <Table columns={columns} loading={loader} dataSource={drawerData} pagination={drawerData.length > 10 ? true : false} />
                <CardDetailsDrawer viewId={viewId} setChange={setDataChanges} open={open} onClose={onClose} /> */}
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

            </div>



            {/* <ToastContainer /> */}

        </div>
    )
}

export default Cards
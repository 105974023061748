import axios from '../api/Axios'
import { axiosPrivate } from './AxiosPrivate'

export const login = (logindata) => axios.post('/login', logindata)

export const createAdmin = (adminDetails) => axios.post('/create-admin', adminDetails)

export const editAdmin = (id, adminDetails) => axios.put(`/edit-admin/${id}`, adminDetails)

export const viewAdmins = () => axiosPrivate.get('/view-admins')

export const getSigleAdmin = () => axiosPrivate.get('/single-admin')

export const createUser = (userDetails) => axios.post('/create-user', userDetails)

export const editUser = (id, userDetails) => axios.put(`/edit-user/${id}`, userDetails)

export const viewUsers = () => axiosPrivate.get('/view-users')

export const getSigleUser = () => axiosPrivate.get('/single-user')

export const viewCards = () => axiosPrivate.get('/view-cards')

export const adminBlock = (id) => axiosPrivate.put(`/block-admin/${id}`)

export const userBlock = (id) => axiosPrivate.put(`/block-user/${id}`)

export const adminDelete = (id) => axios.put(`/delete-admin/${id}`)

export const userDelete = (id) => axiosPrivate.put(`/delete-user/${id}`)

export const getTrash = (id) => axiosPrivate.get(`/trash`)

export const restoreTrash = (id) => axiosPrivate.put(`/restore-trash/${id}`)

export const adminCards = (id) => axios.get(`/admin-cards/${id}`)

export const userCards = (id) => axiosPrivate.get(`/user-cards/${id}`)

export const getOrders = (id) => axiosPrivate.get(`/orders`)

export const ChangeDeliveryStatus = (id, value) => axiosPrivate.put(`/change-status/${id}`, { value })

export const DeactiveCard = (id) => axiosPrivate.post(`/deactiveCard/${id}`)

export const DeleteCard = (id) => axiosPrivate.post(`/deleteCard/${id}`)

export const getMaterialDetails = (id) => axiosPrivate.get(`/get-materialDetails/${id}`)   //nmaterials details

export const getVistorsList = () => axiosPrivate.get('/visitors-list')  //visitors list

/* -------------------------------- card wise ------------------------------- */
export const adminBusinessCards = (id) => axios.get(`/admin-businessCards/${id}`)

export const userBusinessCards = (id) => axiosPrivate.get(`/user-businessCards/${id}`)

export const adminReviewCards = (id) => axios.get(`/admin-reviewCards/${id}`)
export const adminTableTaker = (id) => axios.get(`/admin-tableTaker/${id}`)
export const userTableTaker = (id) => axios.get(`/user-tableTalker/${id}`)

export const userReviewCards = (id) => axiosPrivate.get(`/user-reviewCards/${id}`)

export const adminContactCards = (id) => axios.get(`/admin-contactCards/${id}`)

export const userContactCards = (id) => axiosPrivate.get(`/user-contactCards/${id}`)

/* --------------------------------- coupon --------------------------------- */

export const viewCoupons = () => axiosPrivate.get('/view-coupons')

export const createCoupon = (couponDetails) => axios.post('/create-coupon', couponDetails)

export const createZeroCoupon = (couponDetails) => axios.post('/create-zeroCoupon', couponDetails)

export const couponWiseCards = (id) => axios.get(`/couponWise-cards/${id}`)

export const editCoupon = (id, couponDetails) => axios.put(`/edit-coupon/${id}`, couponDetails)

export const deactiveCoupon = (id) => axiosPrivate.put(`/block-coupon/${id}`)

export const deleteCoupon = (id) => axios.put(`/delete-coupon/${id}`)

/* -------------------------------- products -------------------------------- */
export const viewProduct = () => axiosPrivate.get('/view-products')

export const getMaterialList = () => axiosPrivate.get('/getMaterisl-list')

export const createProduct = (productDetails) => axios.post('/create-product', productDetails)

export const editProduct = (id, productDetails) => axios.put(`/edit-product/${id}`, productDetails)

export const deactiveProduct = (id) => axiosPrivate.put(`/block-product/${id}`)

export const deleteProduct = (id) => axios.put(`/delete-product/${id}`)


/* --------------------------------new products -------------------------------- */
export const viewAllProduct = () => axiosPrivate.get('/view-allProducts')

export const viewVariant = (id) => axiosPrivate.get(`/view-variants/${id}`)

export const getProductImages = (id) => axiosPrivate.get(`/productImages/${id}`)

export const createNewProduct = (productDetails) => axiosPrivate.post('/create-NewProduct', productDetails)

export const addVariant = (productID, variantDetails) => axiosPrivate.post(`/add-variant/${productID}`, variantDetails)


export const updateProduct = (id, productDetails) => axios.put(`/update-product/${id}`, productDetails)

export const updateVariant = (id, variantDetails) => axios.put(`/update-variant/${id}`, variantDetails)

export const deactiveProducts = (id) => axiosPrivate.put(`/deactive-product/${id}`)

export const deleteProducts = (id) => axios.put(`/delete-products/${id}`)

export const deactiveVariant = (id) => axiosPrivate.put(`/deactive-variant/${id}`)

export const deleteVariant = (id) => axios.put(`/delete-variant/${id}`)

export const cloneVariant = (id) => axios.put(`/clone-variant/${id}`)



/* --------------------------------- Themes --------------------------------- */
export const getThemes = () => axiosPrivate.get('/view-themes')

export const createThemes = (themeDetails) => axios.post('/create-theme', themeDetails)

export const editThemes = (id, themeDetails) => axios.put(`/edit-theme/${id}`, themeDetails)

export const deactiveTheme = (id) => axiosPrivate.put(`/block-theme/${id}`)

export const deleteTheme = (id) => axios.put(`/delete-theme/${id}`)

/* --------------------------------- Languages --------------------------------- */
export const getLanguages = () => axiosPrivate.get('/view-languages')

export const getTranslationData = () => axiosPrivate.get('/translations')

export const createLanguages = (languageDetails) => axios.post('/create-language', languageDetails)

export const editLanguages = (id, languageDetails) => axios.put(`/edit-language/${id}`, languageDetails)

export const deactiveLanguages = (id) => axiosPrivate.put(`/block-language/${id}`)

export const deleteLanguages = (id) => axios.put(`/delete-language/${id}`)

/* --------------------------------- Materials --------------------------------- */
export const getMaterials = () => axiosPrivate.get('/view-materials')

export const createMaterials = (materialDetails) => axios.post('/create-materials', materialDetails)

export const editMaterials = (id, materialDetails) => axios.put(`/edit-materials/${id}`, materialDetails)

export const deactiveMaterials = (id) => axiosPrivate.put(`/block-materials/${id}`)

export const deleteMaterials = (id) => axios.put(`/delete-materials/${id}`)

/* --------------------------------- Banner --------------------------------- */
export const getBanner = () => axiosPrivate.get('/view-banner')

export const createBanner = (bannerDetails) => axios.post('/create-banner', bannerDetails)

export const editBanner = (id, bannerDetails) => axios.put(`/edit-banner/${id}`, bannerDetails)

export const deactiveBanner = (id) => axiosPrivate.put(`/block-banner/${id}`)

export const deleteBanner = (id) => axios.put(`/delete-banner/${id}`)




// -----------------------------Location Clear ----------------
export const singleLocClear = (id) => axios.put(`/singleLocClear/${id}`)
export const bukLocationClear = (id) => axios.put(`/bukLocationClear/${id}`)
export const userCardLocBukClear = (id) => axios.put(`/userCardLocBukClear/${id}`)

// ----------- REVIEW CARD LOCATION CLEAR --------
export const singleReviewLocClear = (id) => axios.put(`/singleReviewLocClear/${id}`)
// ----------- ContactCard LOCATION CLEAR --------
export const singleContactCardLocClear = (id) => axios.put(`/singleContactCardLocClear/${id}`)


// -----------Admin qr Update Genarate  ------------

export const updateQrAdminUser = (id) => axios.put(`/updateQrAdminUser/${id}`)
export const singleQrUpdate = (id) => axios.put(`/singleQrUpdate/${id}`)
export const bulkQrUpdateUserCard = (id) => axios.put(`/bulkQrUpdateUserCard/${id}`)


// ---------------- PASSWORD CHANGE -----------
export const changeAdminPassword = (data) => axios.put(`/changeAdminPassword`, data)
export const changeUserPassword = (data) => axios.put(`/changeUserPassword`, data)

// -------- USER MOVE ----------

export const getAllAdminId = () => axios.get(`/getAllAdmin`)
// export const moveUserUnderAdmin = () => axios.post(`/moveUserUnderAdmin`)
export const moveUserUnderAdmin = (userId, adminId) => 
    axios.post(`/moveUserUnderAdmin`, { userId, adminId });
export const moveUserBetweenAdmins = (userId, newAdminId) => 
    axios.post(`/moveUserBetweenAdmins`, { userId, newAdminId });
export const makeUserNormal = (userId) => 
    axios.post(`/makeUserNormal`,{userId});





// --- ADMIN CARD COUNT ---

export const adminCardCounts = (id) => axios.get(`/adminCardCounts/${id}`)





export const logout = (refToken) => axios.post('/logout', { refToken })




import React from 'react'
// import CreateAdmin from '../../components/admin/createAdmin/CreateAdmin'
import Sidebar from '../../components/sidebar/Sidebar';
import { Layout } from 'antd';
import ChangePassword from '../../components/changePassword/ChangePassword';
const { Content } = Layout;

function ChangePasswordPage() {
  return (
    <div>

      <Layout>
        <div className='fixed'>
          <Sidebar />
        </div>
        <Layout style={{ marginLeft: 200, }}  >
          <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: 'white', }} >
            <ChangePassword />
          </Content>
        </Layout>
      </Layout>

    </div>
  )
}

export default ChangePasswordPage